<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default 
{
	name: 'App',

	beforeCreate()
	{
		this.$store.commit('setHeader', { name: 'x-access-key', content: this.$cookie.get('auth_key') })
	}
}
</script>

<style lang="scss">

</style>
