<template>
    <button
        class="menu-item"
        :class="{ 'is-active': isActive ? isActive(): null }"
        @click.prevent="action"
        :title="title"
    >
        <template v-if="icon">
            <svg class="remix">
                <use :xlink:href="require('remixicon/fonts/remixicon.symbol.svg') + `#ri-${icon}`" />
            </svg>
        </template>
        <template v-else>
            <span style="font-size: 10px;">{{title_short}}</span>
        </template>
    </button>
</template>

<script>
import 'remixicon/fonts/remixicon.css'


export default {
    props: {
        icon: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        title_short: {
            type: String,
            required: false,
        },

        action: {
            type: Function,
            required: true,
        },

        isActive: {
            type: Function,
            default: null,
        },
    },
}
</script>

<style lang="scss" scoped>
.menu-item {
    width: 1.55rem;
    height: 1.55rem;
    color: #0D0D0D;
    border: none;
    background-color: transparent;
    border-radius: 0.4rem;
    padding: 0.25rem;
    margin-right: 0.25rem;

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }

    &.is-active,
    &:hover {
        color: var(--text_color_invert);
        background-color: #0D0D0D;

        svg 
        {
            fill: var(--text_color_invert);
        }
    }
}
</style>