import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store.js'

Vue.use(VueRouter)

const View__Home        = () => import(/* webpackChunkName: "view__home" */ '@/components/Views/Home.vue')
const View__Feed        = () => import(/* webpackChunkName: "view__home" */ '@/components/Views/Feed.vue')
const View__Login       = () => import(/* webpackChunkName: "account" */ '@/components/Views/Account/Login.vue')
const View__Register    = () => import(/* webpackChunkName: "account" */ '@/components/Views/Account/Register.vue')
const View__AccountHome = () => import(/* webpackChunkName: "account" */ '@/components/Views/Account/Home.vue')
const View__AccountOver = () => import(/* webpackChunkName: "account" */ '@/components/Views/Account/Overview.vue')


const routes = [
	{
		path: '/',
		component: View__Home,
		
		children: 
		[
			{
				path : '',
				name : 'Home',
				component: View__Feed
			},

			{ path: '/account/login', name: 'Home.Login', component: View__Login },
			{ path: '/account/register', name: 'Home.Register', component: View__Register },

            {
                path: '/account',
                name: 'AccountHome',
                component: View__AccountHome,
                meta: { requiresAuth: true, allowRedirect: true },
                redirect: { name: 'AccountHome.Overview' },
                children:
                [
                    { path: '', name: 'AccountHome.Overview', component: View__AccountOver },
                ]
            },
		]
    }
]

const router = new VueRouter({
    scrollBehavior(to, from, next) {
        return { x: 0, y: 0, behavior: 'smooth', };
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})



router.beforeEach((to, from, next) =>
{
    // to.matched.some(record => { console.log(record);  record.meta.requiresAuth })
    if (to.matched.some(record => record.meta.requiresAuth))
    {
        if (!store.state.user || !store.state.user.auth || new Date(store.state.user.auth.expireAt) < new Date())
        {
            if (to.matched.some(m => m.meta.allowRedirect))
            {
                next({ name: 'Login', query: { redirectTo: to.name } })
            }
            else
            {
                next({ name: 'Login' })
            }
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})



export default router
