import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueCookie from 'vue-cookie'
import VeeValidate from 'vee-validate';
import VueSanitize from "vue-sanitize";
import VModal from 'vue-js-modal'




Vue.config.productionTip = false
Vue.config.ignoredElements = ['h7', 'h8', 'h9', 'h10']

let defaultOptions = {
  allowedTags: ['a', 'b', 'h5', 'h6', 'strong', 'italic', 'p', 'div', 'br'],
  allowedAttributes: {
    'a': [ 'href' ]
  }
};
Vue.use(VueSanitize, defaultOptions);
Vue.use(VModal)
Vue.use(VueCookie)
Vue.use(VeeValidate);

import "@/assets/css/_global.scss"
import "@/mixins/DropdownClickDirective"
import "@/assets/fa/css/all.min.css"

import BasicButton from "@/components/Forms/Buttons/BasicButton"
import InteractiveButton from "@/components/Forms/Buttons/InteractiveButton"
import PageInput from "@/components/Forms/PageInput"

import { HTTPRequest } from "@/mixins/HTTPRequest"
import { InputModelConverter } from "@/mixins/InputModelConverter"


Vue.component('InteractiveButton', InteractiveButton)
Vue.component('BasicButton', BasicButton)
Vue.component('PageInput', PageInput)

Vue.mixin(HTTPRequest)
Vue.mixin(InputModelConverter)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
