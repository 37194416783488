// define a mixin object
import Vue from 'vue'

import axios from "axios";
import store from '../store'


export const HTTPRequest =
{
	data: function ()  {
		return {
			getting_visitor_id: false,
		}
	},

	methods:
	{
		request: function (type, url, options, data)
		{
			var vm = this
			return new Promise(async function (resolve)
			{
				try
				{
					for (const key in store.state.headers)
						axios.defaults.headers.common[key] = store.state.headers[key]

					let result = await axios.request({ method: type, url: url, baseURL: process.env.VUE_APP_API_URL, data: data, ...options });

					resolve({ data: result.data.content, error: null, status: result.data.status })
				}
				catch (error)
				{
					resolve({ data: error, error: error })
				}
			})
		},


        api: async function(type, resource, options = {}, data = {})
        {
			let results = this.request.apply(this, [type, process.env.VUE_APP_API_URL + resource, options, data])
			return results
        }
	}
}
