<template>
	<div class="Page__InputContainer" v-on:mouseover="mouse_over_handler">

		<!-- Basic text input, with optional inline submission -->
		<template v-if="!inputType || inputType == 'text' || inputType == 'number' || inputType == 'otp' || inputType == 'tel'">

			<label class="Page__InputLabel"><slot name="label"></slot></label>
			<div class="Page__InputInputContainer">
				<div class="Page__InputFollowerText" 
                     v-bind:style="{ 'left': input_width + 'px' }" v-if="input_width > 0 && internal_input_value && hasFollowerText">
					<slot name="followerText"></slot>
				</div>

				<input 
					class="Page__Input" 
					
					:name="name"
               		:autocomplete="autocomplete"
					:type="inputType || 'text'" 
					:placeholder="placeholderText" 
					:value="internal_input_value" 
					:maxlength="maxlength"
					:min="min" :max="max"
					:disabled="disabled"

                    v-validate="validate"
					v-bind:class="{ 'PageInput__Loading': status == 2, 'PageInput__Success': status == 3, 'PageInput__Capitalise': capitalise, 'PageInput__Lowercase': lowercase, 'PageInput__Transparent': inputType == 'otp' }"
					@keyup.enter="enterHandler" ref="inputBox"
					@input="on_input_value_change"
					@paste="on_input_value_change"
				/>
			</div>


			<div class="Page__Input_StateContainer">
				<template v-if="status == 2">
					<i class="fas fa-spinner fa-spin"></i>
					Validating
				</template>

				<div class="PageInput__submitText" v-if="status == 1" @click="enterHandler">
					Check
					<i class="fas fa-arrow-right"></i>
				</div>

				<div class="PageInput__submitText success" v-if="status == 3">
					<span v-if="customValidationText">{{ customValidationText }}</span>
					<span v-if="!customValidationText">Done</span>
					<i class="fas fa-check"></i>
				</div>

				<div class="PageInput__submitText" v-if="status == 4"><i class="far fa-times-circle"></i> Error</div>
			</div>
		</template>

		<template v-if="inputType === 'dropdown'">
			<label class="Page__InputLabel"><slot name="label"></slot></label>

			<select :name="name" class="Page__Input" @change="on_input_value_change($event, 'dropdown')">
				<option v-for="(value, $index) in values" :key="value._id || $index" :value="value.value || value">{{ value.title || value }}</option>
			</select>
		</template>


		<template v-if="inputType === 'searchableDropdown'">

			<label class="Page__InputLabel Page__Flex M_Row">
				<slot name="label"></slot>
                <p class="Page__Text small Page__DisplayInlineBlock MarginLeft_x1" v-if="multiple">(can select multiple)</p>
			</label>

			<div class="Page__InputDropdownContainer" 
				 v-clicked-outside="onUnFocusDropdown" 
				 v-bind:class="{ 'open': isInputFocused, 'hasSelected': ((multiple) 
									? !isInputFocused && (dropdownItemSelected && dropdownItemSelected.length > 0)
									: dropdownItemSelected) }">
				
				<div class="Page__Input dropdown" 
					 @click="onFocusDropdown" 
					 v-bind:class="{ 'PageInput__Loading': status == 2, 
					 				 'PageInput__Success': status == 3, 
									 'PageInput__Capitalise': capitalise, 
									 'PageInput__Lowercase': lowercase, 
									 'PageInput__DropdownOpen': isInputFocused }">
					<input class="" 
						   ref="dropdownSearchInput"
						   v-if="(multiple) 
									? isInputFocused || (!dropdownItemSelected || dropdownItemSelected.length < 1)
									: !dropdownItemSelected" 
						   type="text" :placeholder="placeholderText" 
						   :value="internal_input_value" 
						   :maxlength="maxlength"
						   @focus="onFocusDropdown" 
						   @input="on_input_value_change($event, 'searchableDropdown')" 
						   @keyup.enter="enterHandler" 
						   @keyup.esc="onUnFocusDropdown" />
					<div class="Page__DropdownSelectedItems Page__Flex M_Row" v-else>
						<!-- <strong id="kekw">Selected: </strong>  -->
						<div v-if="multiple">
							<div class="Page__Badge MarginRight_x1" v-if="selected"
								 v-for="(selected, $index) in dropdownItemSelected.slice(0,2)" 
								 :key="(selected) ? selected._id : $index">
								{{ selected.name }} <i class="fas fa-times"></i>
							</div>
							<div class="Page__Badge MarginRight_x1" v-if="dropdownItemSelected.length > 2">
								+ {{ dropdownItemSelected.length - 2}} more
							</div>
						</div>
						<div v-else-if="dropdownItemSelected">
							<div class="Page__Badge">{{ dropdownItemSelected.name }} <i class="fas fa-times"></i></div>
						</div>
					</div>
				</div>

				<div class="PageInput__DropdownItemContainer" v-if="isInputFocused && filteredDropdownItems">
					<div class="Page__DropdownSelectedItemsInner Page__Flex wrap M_Row" v-if="(multiple) ? dropdownItemSelected && dropdownItemSelected.length > 0 : dropdownItemSelected">
						<strong>Selected: </strong>
						<template v-if="multiple">
							<div class="Page__Badge" v-for="selected in dropdownItemSelected" :key="selected._id" @click="onDropdownSelectedItem(selected, true)">
								{{ selected.name }} <i class="fas fa-times"></i>
							</div>
						</template>
						<template v-else>
							<div class="Page__Badge">{{ dropdownItemSelected.name }} <i class="fas fa-times"></i></div>
						</template>
					</div>

					<div class="Page__Text small PageInput__DropdownItem"
						 v-if="internal_input_value 
						 	   && internal_input_value.length > 0 
							   && filteredDropdownItems.length < 1">
						<strong>
							<i class="fas fa-info-circle"></i> Info:</strong> 
							It seems none of the available choices match your search criteria,
							please try again using a different phrase.
						<!-- <p class="Page__Text small">If you can't find the breed of your pet,
							you can select the "Other" option below</p> -->
					</div>

					<div class="PageInput__DropdownItem Page__Flex M_Row" 
						 v-for="(dropdownItem, $index) in filteredDropdownItems" 
						 @click="onDropdownSelectedItem(dropdownItem)"
						 :key="dropdownItem._id || $index">
						<div class="PageInput__DropdownItemIcon Page__FlexNoShrink" 
							 v-bind:style="{ 'background-image': 'url(' + dropdownItem.icon_url + ')' }"
							 v-bind:class="{ 'selected': (multiple && dropdownItemSelected && dropdownItemSelected.some(el => el['_id'] === dropdownItem._id )) }"></div>
						<p class="PageInput__DropdownItemHeading Page__Text">
							{{ dropdownItem.name }}
							{{ (multiple && dropdownItemSelected && dropdownItemSelected.some(el => el['_id'] === dropdownItem._id )) ? "(click to deselect)" : "" }}
						</p>
					</div>
				</div>
			</div>

		</template>


		<template v-if="inputType == 'checkbox'">
			<label class="Page__InputLabel Page__Checkbox">
				<input type="checkbox" :checked="internal_input_value" @click="on_input_value_change($event, 'checkbox')" true-value="true" false-value="false" />
				<span><slot name="label"></slot></span>
			</label>
		</template>


		<template v-if="type == 'textarea'">
			<span><slot name="label"></slot></span>
			<textarea class="Page__Input textarea" id="story" name="story" rows="5"  :placeholder="placeholderText" :value="internal_input_value" @input="on_input_value_change" :maxlength="maxlength">

			</textarea>
		</template>

		<div v-if="inputType == 'richText'">
			<span><slot name="label"></slot></span>
            <TipTap :content="internal_input_value" :data="internal_input_value" @editedData="on_input_value_change($event, 'richText')" :basic="basic" :maxlength="maxlength" />
        </div>

		<div class="Page__InputError" v-for="(error, $index) in errors.all()" :key="$index">{{ error }}</div>
	</div>
</template>

<script>
import TipTap from "@/components/Forms/RichText/TipTap";

export default
{
	name: "PageInput.vue",
	components: { TipTap },

	data()
	{
		return {
			internalInputModel: null,
			dropdownItemSelected: null,
			isInputFocused: false,
			input_width: 0,

			internal_input_value: null,
			validation_state: Boolean,
			ctrl_key_pressed: false,
		}
	},

	props:
	{
		name: String,
		inputModel: null,
		inputType: String,
		placeholderText: String,
		maxlength: Number,
		inputTitle: String,
		initialStatus: Number,
		status: Number, /* 0: none, 1: submittable, 2: checking, 3: success, 4: failure */
		otpState: Number /* 0: none, 1: checking, 2: success, 3: failure */,
		customValidationText: String,
		basicTextLocalModel: String,
		capitalise: Boolean,
		lowercase: Boolean,
		optional: Boolean,
		validate: String,
		disabled: Boolean,
		values: null,
		multiple: Boolean,
		autocomplete: null,
        type: null,
		basic: Boolean,

		/* Dropdown Input */
		dropdownItems: Array,
		overrideFocus: Boolean,

		/* Number Input */
		min: Number,
		max: Number
	},

	model:
	{
		prop: 'inputModel',
		event: 'input'
	},

	beforeMount()
	{
		this.populate_values()
	},

	methods:
	{
		populate_values()
		{
			if (typeof this.inputModel !== 'undefined' && this.inputModel != null)
			{
				if (this.inputType != "searchableDropdown")
					if (this.inputModel.hasOwnProperty('inputModel'))
					{
						this.internal_input_value = this.inputModel.inputModel

						if (this.validate)
						{
							this.reset_validation()
							this.emit_value()
						}
					}
					else
					{
						this.internal_input_value = this.inputModel
					}
				else
				{
					this.internal_input_value = (this.multiple) ? [] : null

					if (this.inputModel.hasOwnProperty('inputModel'))
					{
						if (this.inputModel.inputModel instanceof Array 
							&& this.inputModel.inputModel.length > 0)
						{
							if (this.multiple)
							{
								this.dropdownItemSelected = this.inputModel.inputModel.map((el) => {
									if (el.hasOwnProperty('inputModel'))
									{
										if (el.inputModel.hasOwnProperty('_id'))
											return this.values.find(val => val['_id'] === el.inputModel._id)
										else
											return this.values.find(val => { return val['_id'] === el.inputModel})
									}
								})
							}
							else
							{
								this.dropdownItemSelected = 
									(this.inputModel.inputModel[0].inputModel.hasOwnProperty('_id'))
										? this.values.find(val => val['_id'] === this.inputModel.inputModel[0].inputModel._id)
										: this.values.find(val => { return val['_id'] === this.inputModel.inputModel[0].inputModel })
							}
						}
						else
						{
							if (typeof this.inputModel.inputModel !== 'undefined' && this.inputModel.inputModel !== null)
							{
								this.dropdownItemSelected = this.values.find(val => val['_id'] === this.inputModel.inputModel)
								if (this.dropdownItemSelected)
									this.emit_value()
							}
						}
					}
				}
			}
			else
			{
				this.internal_input_value = ""
			}
		},

		mouse_over_handler($event)
		{
			this.ctrl_key_pressed = $event.ctrlKey
		},

		emit_value(event_type)
		{
			if (typeof this.inputModel !== 'undefined' && this.inputModel != null)
			{
				if (this.inputType == 'searchableDropdown')
				{
					if (this.inputModel instanceof Array || this.inputModel.hasOwnProperty('inputModel'))
					{
						if (this.dropdownItemSelected instanceof Array)
							var value = this.dropdownItemSelected.map(a => { return { inputModel: a._id, name: a.name } });
						else var value = (this.dropdownItemSelected && this.dropdownItemSelected._id) 
								? this.dropdownItemSelected._id 
								: null

						this.$emit('input', { 
							inputModel: value,
							name: (this.dropdownItemSelected && this.dropdownItemSelected._id) ? this.dropdownItemSelected.name : "",
							validated: (this.optional && this.internal_input_value.length < 1) 
										? true 
										: this.validation_state 
						})
						this.$emit('changed', value)

						return;
					}
					else
					{
						this.$emit('input', { inputModel: [], validated: false })
						this.$emit('changed', [])

						return;
					}
				}
				else
				{
					if (this.inputModel.hasOwnProperty('inputModel'))
					{
						this.reset_validation()

						this.$emit('input', { 
							inputModel: this.internal_input_value, 
							validated: (this.optional && this.internal_input_value.length < 1) ? true : this.validation_state 
						})
					}
					else
					{
						this.$emit(event_type || 'input', this.internal_input_value)
					}
				}
			}

			this.$emit('changed', this.internal_input_value)
		},


		reset_validation()
		{
			this.validation_state = (typeof this.internal_input_value != 'undefined')
				&& this.internal_input_value != null
				&& ((typeof this.internal_input_value == 'number') 
						? true
						: this.internal_input_value.length > 0)
				&& this.errors.items.length < 1
		},


		on_input_value_change: function($event, type)
		{
			console.log($event)
			if (this.inputType == 'checkbox')
			{
				var value = $event.target.checked
			}
			else if (this.inputType == 'richText')
			{
				if (this.basic)
					var value = $event.state.doc.textContent
				else
					var value = $event.getHTML()
			}
			else if (this.inputType == 'searchableDropdown' && type != 'searchableDropdown')
			{
				var value = this.internal_input_value
			}
			else
			{
                var value = $event.target.value					
			}


			this.internal_input_value = value
			if (type != 'searchableDropdown')
				this.emit_value()


			if (!this.$listeners.input)
				this.internal_input_value = value

			if (!this.inputType || this.inputType == 'text' || this.inputType == 'number')
			{
				var input = this.$refs.inputBox;
				var c = document.createElement("canvas");
				var ctx = c.getContext("2d");
				ctx.font = this.font(input);
				var txtWidth = ctx.measureText(input.value).width;
				this.input_width = parseInt(txtWidth) + parseInt(window.getComputedStyle(input, null).getPropertyValue('padding-left').replace('px','')) + 10;
			}
		},

		font(element) {
			var prop = ["font-style", "font-variant", "font-weight", "font-size", "font-family"];
			var font = "";
			for (var x in prop)
				font += window.getComputedStyle(element, null).getPropertyValue(prop[x]) + " ";

			return font;
		},

		enterHandler: function(data)
		{
			if (this.status == 1)
			{
				this.emit_value('enterEvent')
			}
		},


		onFocusDropdown: function()
		{
			if (this.isInputFocused === false)
			{
				if (!this.multiple)
					this.dropdownItemSelected = null

				this.isInputFocused = true

				this.$nextTick(function() { this.$refs.dropdownSearchInput.focus() })
				this.$emit('onFocus')
			}
		},


		onUnFocusDropdown: function()
		{
			this.$emit('onBlur')
			this.isInputFocused = false
		},


		onDropdownSelectedItem: function(event, keep_focus)
		{
			this.$emit('dropdownItemSelectedEvent', event)

			if (!this.multiple)
			{
				this.dropdownItemSelected = event
				this.on_input_value_change(this.dropdownItemSelected)
			}
			else 
			{
				if (this.dropdownItemSelected && this.dropdownItemSelected instanceof Array)
				{
					if(!this.dropdownItemSelected.some(el => el['_id'] === event._id ))
						this.dropdownItemSelected.push(event)
					else
						this.dropdownItemSelected
							.splice(this.dropdownItemSelected.findIndex(el => el._id === event._id), 1)
				}
				else
					this.dropdownItemSelected = [event]

				this.on_input_value_change(this.dropdownItemSelected)
			}

			if ((keep_focus !== true) && !(this.multiple && this.ctrl_key_pressed === true))
				this.onUnFocusDropdown()
		}
	},


	watch:
	{
		overrideFocus()
		{
			if (this.overrideFocus && !this.isInputFocused) this.onFocusDropdown()
			else if (!this.overrideFocus && this.isInputFocused) this.onUnFocusDropdown()
		},

		values:	{
			deep: true,
			handler: function(newVal, oldVal)
			{
				this.populate_values()
			}
		},

		inputModel: {
			deep: true,
			handler: function (newVal, oldVal)
			{
				if (!newVal)
				{
					this.reset_validation()
					return;
				}

				if (this.inputType !== "searchableDropdown")
				{
					(newVal.hasOwnProperty('inputModel'))
						? this.internal_input_value = newVal.inputModel
						: this.internal_input_value = newVal
				}
				else
				{
					if (newVal.hasOwnProperty('inputModel') && newVal.inputModel instanceof Array && newVal.inputModel.length > 0)
					{
						this.dropdownItemSelected = newVal.inputModel.map((el) => {
							if (el.hasOwnProperty('inputModel'))
							{
								if (el.inputModel.hasOwnProperty('_id'))
									return this.values.find(val => val['_id'] === el.inputModel._id)
								else
									return this.values.find(val => { return val['_id'] === el.inputModel})
							}
						})
					}
				}

			}
		}
	},

	computed:
	{
		hasFollowerText() {
			if (!this.$slots.followerText) return false;
			return !!this.$slots.followerText.length
		},
		filteredDropdownItems:
		{
			get()
			{
				if (this.values == undefined)
					return undefined;

				let resultList = [];
				let none = null

				if (!this.internal_input_value || this.internal_input_value instanceof Array)
					resultList = this.values
				else
				{
					for (let i =0; i < this.values.length; i++) 
					{
						console.log({ toLowerCase: this.internal_input_value })
						if (typeof this.values[i] !== 'undefined' && this.values[i] !== null && this.internal_input_value)
							if (this.values[i].name.toLowerCase().includes(this.internal_input_value.toLowerCase().trim()))
								resultList.push(this.values[i]);

						if (this.values[i]._id == process.env.VUE_APP_PET_NONE)
							none = this.values[i]
					}
				}


				// console.log(process.env.VUE_APP_PET_NONE)
				if (resultList.length < 1 && none)
					resultList.push(none)

				return resultList;
			},

			set() {

			}
		}
	}
}
</script>

<style scoped lang="scss">
    .Page__InputError
    {
        color: var(--background_color_danger);
        font-size: 11px;
        letter-spacing: 0.3px;
        margin-top: 5px;
    }


	.PageInput__DropdownOpen
	{
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.Page__InputDropdownContainer 
	{
		position: relative;

		&::after
		{
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;

			font-family: "Font Awesome 6 Pro";
    		font-weight: 300; 
			font-size: 16px;
			content: "\f0d7";
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
		}

		&.open::after
		{
			transform: translateY(-50%) rotate(180deg);
		}

		&.hasSelected:not(.open)
		{
			.Page__Input 
			{
				padding: 5px 40px 5px 9px;
			}
		}
	}

	.PageInput__DropdownItemContainer
	{
		position: absolute;
		z-index: 4;
		left: 0;
		right: 0;
		background: var(--background_color_main);

		width: 100%;
		border-radius: var(--border_radius_main);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
        border: 1px solid var(--border_color_input);
		border-top: 0;

        background: var(--background_color_main);
        font-size: 10px;
        letter-spacing: 0.3px;

		max-height: 190px;
		overflow-y: scroll;

		.PageInput__DropdownItemIcon
		{
			background: var(--background_color_hero);
			height: 20px;
			width: 20px;
			border-radius: 25px;
			margin-right: 7px;
			vertical-align: middle;

			&.selected
			{
				background: var(--background_color_discord);
			}
		}

		.PageInput__DropdownItem, .Page__DropdownSelectedItemsInner
		{
			padding: 10px 8px;
			vertical-align: middle;
			border-radius: var(--border_radius_main);

			cursor: pointer;

			&:hover
			{
				background: var(--background_color_hero);
			}
		}

		.Page__DropdownSelectedItemsInner
		{
			border-radius: 0;
			border-bottom: 1px solid var(--border_color_input);
			gap: 6px;

			.Page__Badge
			{
				padding: 0px 8px;
				padding-top: 1px;
				font-size: var(--page_text_small);
			}
		}
	}

	.Page__DropdownSelectedItems 
	{
		strong
		{
			font-size: var(--font_size_small);
			text-transform: lowercase;
		}

		.Page__Badge
		{
			padding: 0px 8px;
			padding-top: 1px;
			font-size: 10px;
		}
	}
</style>