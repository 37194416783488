import clone from 'just-clone';

export const InputModelConverter =
{
	methods:
	{
		AddInputModels: function (obj)
		{
            const iterate = (data) => 
            {
                Object.keys(data).forEach(key => 
                {
                    if (typeof data[key] === 'object' 
                        && data[key] !== null) 
                    {
                        if (data[key] instanceof Array)
                        {
                            data[key] = { inputModel: data[key] }
                            iterate(data[key].inputModel)
                        }
                        else 
                            iterate(data[key])
                    }
                    else
                    {
                        data[key] = { inputModel: data[key] }
                    }
                })
            }

            let work = clone(obj)
            iterate(work)
            return work
        },

        FlattenInputModels: function(obj)
        {
            const iterate = (data) => 
            {
                if (!data)
                    return data 
                    
                Object.keys(data).forEach(key => 
                {
                    if (typeof data[key] !== "undefined" && data[key] !== null)
                    {
                        if (key == 'inputModel')
                        {
                            data = data[key]
                            iterate(data)
                        }
                        else if (data[key].hasOwnProperty('inputModel'))
                            data[key] = data[key].inputModel


                        if (typeof data[key] === 'object')
                        {   
                            iterate(data[key])
                        }
                    }


                })

                return data
            }

            let work = clone(obj)
            return iterate(work)
        },

        CanBeSubmitted: function(obj)
        {
            const iterate = (data) => 
            {
                let retVal = true
                Object.keys(data).forEach(key => 
                {
                    if (typeof data[key] !== 'undefined' && data[key] !== null)
                    {
                        if (data[key].hasOwnProperty('inputModel'))
                        {
                            if (data[key].hasOwnProperty('validated') && !data[key].validated)
                            {
                                retVal = false
                            }
                        }
                        else if (typeof data[key] === 'object')
                        {
                            iterate(data[key])
                        }
                    }
                })

                return retVal
            }

            let work = clone(obj)
            console.log(iterate(work))
            if (iterate(work) === false)
                return false

            return true
        }
	}
}
