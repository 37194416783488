import Vue from 'vue'

Vue.directive('clicked-outside', {
	bind: function (el, binding, vnode) {
		el.clickOutsideEvent = function (event) {
			if (!document.body.contains(event.target))
				return false

			// const iterate = (data) => 
            // {
			// 	NodeList.prototype.forEach = Array.prototype.forEach
			// 	var children = data.childNodes;
			// 	children.forEach(function(item)
			// 	{
			// 		console.log(item);
			// 		iterate(item)
			// 	});
			// }

			// iterate(el)


			// here I check that click was outside the el and his children
			if (!(el == event.target || el.contains(event.target))) {
				// and if it did, call method provided in attribute value
				vnode.context[binding.expression](event);
			}
		};
		document.body.addEventListener('click', el.clickOutsideEvent)
	},
	unbind: function (el) {
		document.body.removeEventListener('click', el.clickOutsideEvent)
	},
});
