<template>
    <div>

        <button class="OtpInput__Clear" @click="btnClickHandler" :disabled="disabledT">
            <span v-if="status == 0">
                <span style="vertical-align: middle"> {{ buttonText[0] }} </span>
                <i class="ri-arrow-right-line" style="font-size: inherit;vertical-align: middle"></i>
            </span>
            <span v-show="status == 1">
                <i class="fas fa-spinner fa-spin"></i> {{ buttonText[1] }}</span>
            <span v-if="status == 2"><i class="far fa-check-circle"></i> {{ buttonText[2] }}</span>
            <span v-if="status == 3"><i class="far fa-times-circle"></i> {{ buttonText[3] }}</span>
        </button>
    </div>
</template>

<script>
    import 'remixicon/fonts/remixicon.css'


    export default
    {
        name: "BasicButton",
        props:
        {
            buttonText: null,
            status: Number,
            disabledT: Boolean,
            fnParam: Function
        },
        methods:
        {
            async btnClickHandler()
            {
                if (this.status != 1)
                {
                    this.$emit('update:status', 1)

                    if (typeof this.fnParam == 'function')
                    {
                        let result = await this.fnParam()
                        this.$emit('update:status', (result) ? 2 : 3)
                    }
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
    button.OtpInput__Clear
    {
        background: linear-gradient(to left, #8d39cc, #6844d8);
        border: none;
        color: var(--text_color_invert);
        padding: 5px 10px;
        padding-top: 3px;
        border-radius: 5px;
        display: inline-block;

        & *
        {
            font-size: 11px;
        }

        &:disabled
        {
            background: #d2d2d2;
            color: #000;
            border: 1px solid #989898;
            cursor: no-drop;
        }
    }
</style>