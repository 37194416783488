import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'


Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})


//init store
const store = new Vuex.Store({
    state: {
        globalError: '',
        user: {},
        justRegistered: false,
		headers: []
    },
    mutations: {
        setGlobalError (state, error)
        {
            state.globalError = error
        },

        setUser (state, userInfo)
        {
            state.user = userInfo
        },

        setFirstLogin (state, which)
        {
            state.justRegistered = which
        },
        setHeader (state, header)
        { 
			let array = state.headers
			array[header.name] = header.content 
			state.headers = array
		}
    },
    plugins: [vuexLocal.plugin]
})


export default store