<template>
	<div :is="type || 'button'" type="button" v-bind:onLoading="testVar" v-on:click="onClick"
	     :class="{ 'success': status == 4, 'danger': status == 3  }" :disabled="status != 0 && status != 4">
		<slot name="default" v-if="status == 0">Button</slot>
		<slot name="loading" v-else-if="status == 1">Loading <i class="fas fa-circle-notch fa-spin"></i></slot>
		<slot name="warning" v-else-if="status == 2">Warning <i class="fas fa-exclamation-triangle"></i></slot>
		<slot name="error" v-else-if="status == 3">Error <i class="fas fa-exclamation-circle"></i></slot>
		<slot name="error" v-else-if="status == 4">Success <i class="far fa-check-circle"></i></slot>
	</div>
</template>

<script>
export default
{
	name: "InteractiveButton",
	data()
	{
		return {
			testVar: "aaaaaa",
			status: 0
		}
	},
	props:
	{
		type: String
	},
	methods:
	{
		onClick()
		{
			console.log('clicked')
			this.$emit("clicked", this)
		},
		onDefault()
		{
			this.status = 0
		},
		onLoading()
		{
			this.status = 1
		},
		onWarning()
		{
			this.status = 2
		},
		onError()
		{
			this.status = 3
		},
		onSuccess()
		{
			this.status = 4
		},
	}
}
</script>

<style scoped>

</style>
